import React from "react";
import "../CSS/footer.css";
import {AiFillFacebook} from 'react-icons/ai';
import {AiFillInstagram} from 'react-icons/ai';
import { IconContext } from "react-icons";
import { NavLink,Link } from "react-router-dom";
const Footer =()=>
{
    const date= new Date();
    return(
        <div className="containerFooter">
            <div className="numeEchipa">
                <p>Accessible Tech Solutions</p>
            </div>
            <div className="politicaSubsol">
                <div className="politicaSubsolctrl">
                    <div >
                        <span className="sectionTitle" style={{color: '#050B93'}}>Accessible Tech Solutions</span>
                    </div>
                    <div >
                        <span className="sectionTitle">Despre noi</span>
                        <br />
                        <span>Echipa noastra este formata din membrii tineri și deschiși spre a înțelege și  rezolva problemele cât și cerințele dumneavoastră.</span>
                    </div>
                    <div >
                        <span className="sectionTitle">Site Map</span>
                        <br />
                     
                      <span>  <Link to="/">Acasa</Link></span>
                        <br />
                        <span><Link to="/DespreNoi">Despre noi</Link></span>
                        <br />
                        <span><Link to="/Servicii">Servicii</Link></span>
                        <br />
                        <span><Link to="/Contact">Contact</Link></span>
                    </div>
                    <div >
                        <span className="sectionTitle">Contact</span>
                        <br />
                       
                        <span> <a  href="mailto:atechsolutions.ro"> atechsolutions@atechsolutions.ro</a></span>
                        <br />
                        <span></span>
                    </div>
                </div>
            </div>
            <div className="copyRightContainer">
                <div className="parent2">
                <div className="cpyRights">
                    <span>{`Copyright © ${date.getFullYear()} Accessible Tech Solutions`}</span>
                </div>
                <div className="social">
                    <IconContext.Provider value={{color:'white' , size:'40px'
                         }} >
                    {/* <AiFillFacebook />
                    <AiFillInstagram/> */}
                    </IconContext.Provider>
                </div>
                </div>
            </div>
        </div>
    );
 }

 export default Footer;