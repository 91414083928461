import React from "react";
import { useState } from "react";
import "../CSS/servicii.css";
import SoftwareDevelopment from "./SoftwareDevelopment";
import NetworkManagement from "./NetworkManagement";
import PaginaSD from "./PaginaSD";
import "../CSS/softwaredevelopment.css";


import {BrowserRouter as Router  , Route, BrowserRouter,Routes,NavLink,Link} from "react-router-dom";
const nume=['Software development','Networking management','Automatisations','Soluții integrate de management operațional și leadership'];
const descrieri=[' Serviciile noastre pe partea de web sunt variate, de la design si realizarea a site-urilor web, platforme de comerț electronic până la înregistrarea de domenii și servicii Web.',
' Sistemele de baze de date sunt folosite pentru a stoca volume mari de informație ce sunt necesare pentru buna funcționare a unei organizații. ',
'Pe partea de rețelistică oferim servicii de Sharing , Email and Website Hosting ',
'   Oferim o evaluare activitate si retete complete pentru a va asigura directia optima de abordare in business in general si pesonalizat punctual pentru nevoi reale.']
const subdomenii=['Web development','Baze de date','Rețelistică','Ce facem?']
const paths=['/SoftwareDevelopment','/NetworkingManagement','/Automatisations','/ManagementSolutions']

function functie(nume , subtitlu , descriere,subtitlu2,descriere2,paths)
{
  
    return(
        <div className="containerSD">
        <div>
            <span>{nume}</span>
        </div>
        <div className="parinteSD">
            <br />
            <p>
                {subtitlu}
            </p>
        <span>
       
        {
            descriere
        }
        </span>
        <br />
        <br />
        <br />
            <p>
                {subtitlu2}
            </p>
        <span>
            {descriere2}
        </span>
        <Link to={`${paths}`}>
        <div className="redirectPagina">
           <button>Citeste mai mult</button>
        </div>
        </Link>
        </div>
        
        
    </div>
    );
}


function verificare(specializare)
{   let v;
    console.log(specializare);
    switch(specializare)
    {
        case `soft`:
           v= functie(nume[0],subdomenii[0],descrieri[0],subdomenii[1],descrieri[1],paths[0]);
           console.log(v);
            break;
        case `net`:
          v=  functie(nume[1],subdomenii[2],descrieri[2],'','',paths[1])
          console.log(v);
            break;
        case `auto`:
          v=  functie(nume[2],subdomenii[0],descrieri[0],'','',paths[2])
          console.log(v);
            break;
        case `man`:
            v=  functie(nume[3],subdomenii[3],descrieri[3],'','',paths[3])
            console.log(v);
            break;
        
    }
    return v;

}

const Servicii =()=>
{   

    var test; 
    const p=()=>test;
   var valoare
        

    const [count,setCout]=useState('');
    return(
        <div>
            <div className="backgroundServicii">
            <span>SERVICII</span>
            </div>
            <div className="containerCategorii">
             
               <div  onClick={()=>  setCout(verificare(`soft`))}>
                    
                    <div className="SDimage">
                        <img src={require('../Images/webDevelopment2.png')} alt="img" />
                        
                    </div>
                    <br />
                    <span>Software Development</span>
                    
                   
                </div>
                
                <div onClick={()=>setCout(verificare(`net`))}>
                    
                    <div className="NMimage" >
                    <img src={require('../Images/networking.png')} alt="img" />
                   
                    </div>
                    <br />
                    <span>Networking management</span>
                    
                    
               
                </div>
                <div onClick={()=>setCout(verificare(`auto`))}>
                    
                    <div className="Aimage" >
                    <img src={require('../Images/automation.png')} alt="img" />
                   
                    </div>
                    <br />
                    <span>Automatisations</span>
                    
                    
                </div>
                <div onClick={()=> setCout(verificare(`man`))}>
                   
                    <div className="MSimage">
                    <img src={require('../Images/ProcesManagerialIcon.png')} alt="img" />
                    
                    </div>
                    <br />
                    <span>Management solutions</span>
                    
                </div>

            </div>
            {
                count
                    //test()
                   
            }
               
        {/* <Routes>
          <Route path='/soft' element={<SoftwareDevelopment/>} />
          <Route path='/net' element={<NetworkManagement/>} />
        </Routes> */}
        
            
        </div>
    );
}

export default Servicii;