import  React from 'react'
import '../CSS/networking.css'; 
const Networking=()=>
{
    return(

        <>
            <div className='titleNet'>
                <span>Networking</span>
            </div>
            <div className='imagineNet'>
                <div className='copilImagineNet'>

                </div>
            </div>
            <div className='containerNet'>
            <div className='svNet'>
                <span>Servere</span>
                <p>Cream servere si oferim mentenanta  indiferent de domeniul de aplicare .</p>
                <p>Serverul reprezinta modalitatea prin care datele intreprinderii tale pot fi stocate si accesate eficient in siguranta crescand productivitatea si eficientizand procese. </p>
                <span>Deployment & hosting </span>
                <p>
                Daca doriti sa va expuneti site-ul  publicului larg acest lucru se pote face cu usurinta in in cadrul companiei noastre eficient din punct de vedere economic .
                </p>
                <span>Supraveghere video</span>
                <p>Asiguram servicii de calitate pentru monitoarizarea video a spatiilor industriale , spatii locuibile , terenuri etc  prin echipamente noi , performante ,integrarea inteligentei artificiale si cunoasterea inginerilor nostri.</p>
            </div>
            </div>
        </>
    );
}
export default Networking;