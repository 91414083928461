import React from 'react'
import '../CSS/automatisations.css'

const Automatisations=()=>
{
    return(
        <>
            <div className='titluAuto'>
                <span>Automatisations</span>
            </div>
            <div className='containerAutomatizari'>
                <div className='imgAuto1'>

                </div>
                <div >
                    <span className='subtitluAutomatizari'>
                        Automatizari
                    </span>
                    <p>Cream sisteme de monitorizare a proceselor de productie ce opereaza automat .
</p>
                    <p>Construim machete, roboti si utilaje in funtie de necesitatile clientilor.</p>
                </div>
            </div>
           <div className='containerPLC'>
           <div>
                    <span>
                    PLC & HMI industry
                    </span>
                    <p>  Vrei sa iti creezi un utilaj de productie intensa sau ai nevoie de mentenanta unuia? </p>
                    <p>Ne adaptam nevoilor tale raportat la bugetul oferit iti putem cea mai buna calitate.</p>
            </div>
                <div>

                </div>
           </div>
        </>
    );
}

export default Automatisations;