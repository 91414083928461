import React from 'react';
import '../CSS/desprenoi.css'
 const DespreNoi =()=>
 {
    return(
        <>
            <div className="titluDespreNoi">
                <span>Despre noi</span>

            </div>
            <div className="descriereDespre">
                <p>
                Echipa noastra este formata din membrii tineri și deschiși spre a înțelege și  rezolva problemele cât și cerințele dumneavoastră, spre a vă satisface nevoile într-un mod cât mai simplu.
                </p>
            </div>
            <div className="containerDespreNoi1">
                <div>
                    <span>
                    Misiunea noastră se bazează pe creșterea productivă a clienților noștri, oferind instrumente, cunoștințe și Tehnologia Informației pentru a satisface cerințele acestora, promovând o dezvoltare armonioasă a talentului nostru uman.
                    </span>
                </div>

                <div>
                </div>
            </div>
            <div className="containerDesoreNoi2">
                <div>
                </div>
                <div>
                    <span>
Valorile noastre de afaceri se bazează pe un principiu clar al serviciilor, calitate, onestitate și angajament. Principiile noastre corporative sunt strâns legate de îmbunătățirea continuă, competitivitatea, munca în echipă, angajamentul talentului uman și participarea democratică a resurselor noastre umane.
                    </span>
                </div>
            </div>
        </>
    );
 }

 export default DespreNoi; 