import React from 'react'
import '../CSS/paginaSM.css'


const PaginaSM=()=>
{
    return(
        <div>
            <div className='titleSM'>
            <span > Management solutions</span>
            </div>
            <div className='subtitluSM'>
                <span>Solutii integrate de management operational si leadership</span>
            </div>
            
            <div className='containerCefacem' >
                <div className='parinteCeFacem'>
                    <div className="copilCeFacem">
                    <span>Ce facem?</span>
                    <p>  Oferim o evaluare activitate si retete complete pentru a va asigura directia optima de abordare in business in general si pesonalizat punctual pentru nevoi reale.</p>
                    </div>

                </div>
                <div className="parinteImagine">
                    <div className="copilImagine">

                    </div>
                </div>
            </div>
            <div className='containerCumfacem'>
               <div className='CumfacemImagine'>

               </div>
               <div className='CumfacemText'>
                    <span>Cum facem?</span>
                    <p>  Va ajutam sa evaluati punctual resursele existente si oferim solutii de utilizarea la maxim.</p>
                    <p>  Construim un management eficient al resurselor, al activitatatilor curente, al cash-flow-ului, al angajatilor si evident al timpului.</p>
                    <p>  Lucram cu procese-proceduri coerente si fise clare in activitate planificate SMART.
</p>
                    <p>  Va ajutam sa faceti analize SWOT pe activitate si propunem solutii concrete de analiza risc.</p>
                    <p>  Automatizam, oferim si centralizam fluxuri de baze de date si le facem accesibile remote, doar la un clic distanta.</p>
               </div>
            </div>

        </div>
    );
}

export default PaginaSM;