import React from 'react';
import {BrowserRouter as Router ,Switch , Route, BrowserRouter,Routes,NavLink} from "react-router-dom";
import Header from './Componente/Header';
import Footer from './Componente/Footer';
import Acasa from './Componente/Acasa';
import Contact from './Componente/Contact';
import Servicii from './Componente/Servicii';
import SoftwareDevelopment from './Componente/SoftwareDevelopment';
import NetworkManagement from './Componente/NetworkManagement';

import PaginaSD from './Componente/PaginaSD';
import PaginaSM from './Componente/PaginaSM';
import Networking from './Componente/Networking';
import Automatisations from './Componente/Automatisations';
import DespreNoi from './Componente/DespreNoi';
function App() {
  return (
    <div className="App">
    
      <Router>
        <Header/>
        
         <Routes> 
          
          <Route path='/' element={<Acasa></Acasa>} />
          <Route path='/Contact' element={<Contact></Contact>} />
          <Route path='/Servicii' element={<Servicii></Servicii>}> </Route>  
          <Route path='/SoftwareDevelopment' element={<PaginaSD></PaginaSD>} />
          <Route path='/NetworkingManagement' element={<Networking></Networking>} />
          <Route path='/Automatisations' element={<Automatisations></Automatisations>} />
          <Route path='/ManagementSolutions' element={<PaginaSM></PaginaSM>} />
          <Route path='/DespreNoi' element={<DespreNoi></DespreNoi>} />

        </Routes> 
        <Footer/>
      </Router>
    
    </div>
  );
}

export default App;
