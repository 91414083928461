import React from "react";
import '../CSS/acasa.css';
import { NavLink,Link } from "react-router-dom";
const Acasa =()=>
{
return (
    <div>
    <div className="containerHeader">
        <div className="scris1" >
           <p> Suntem aici pentru a îți simplifica viața </p>
        </div>
        <div className="scris2">
                <p>Avem rezolvarea oricărei probleme și servicii din domeniul IT</p>
        </div>
        
        <div className="buton3">
        
            <button type="button">
            <Link to='/Contact'>
                    Contacteăza-ne
            </Link>
            </button> 
            
        </div>
        
       
    </div>
    <div style={{height:'100px'}}></div>
    <div className="divOverDiv">
                <span>
                    NOI SUNTEM ACCESSIBLE TECH SOLUTIONS
                </span>
                <br />
                <span>
                Echipa noastra este formata din ingineri tineri și deschiși spre a înțelege și  rezolva problemele cât și cerințele dumneavoastră, spre a vă satisface nevoile într-un mod cât mai simplu.
                </span>
        </div>
    <div className="control123">
            
    </div>

    <div className="containerMisiune">
        <div className="parinteMisiune">
            <p>
                Misiune și viziune
            </p>
            <p>
            Misiunea noastră se bazează pe creșterea productivă a clienților noștri, oferind instrumente, cunoștințe și Tehnologia Informației pentru a satisface cerințele acestora, promovând o dezvoltare armonioasă a talentului nostru uman.
            </p>
        </div>
    </div>
    <div className="containerNevoie">
         <p>De ce ai nevoie de noi!</p>
        <div className="parinteNevoie">
          
            <div className="containerImagini">
                <div className="imgStanga">
                    <span>Pentru a crește venuturile companiei tale.</span>
                </div>
                <div className="imgMijloc">
                    <span>Îmbunătățirea fluxului de muncă al companiei.</span>
                </div>
                <div className="imgDreapta">
                    <span>Pentru crearea unui spațiu online de promovare a produselor / seviciilor dumneavoastră.</span>
                </div>
            </div>
        </div>
    </div>
    </div>
);
}

export default Acasa;