import React,{useState,useEffect} from "react";
import  "../CSS/header.css";
// import {toggleMobileMenu} from '../JS/MobileNavbar';
import { NavLink,Link } from "react-router-dom";
import {MdOutlineKeyboardArrowDown} from 'react-icons/md';

const Header =()=>
{   const [deschis,setDeschis] = useState("")
const [toggleMenu,setToggleMenu] = useState(false);
const [subMenu,setSubMenu]=useState(false);

const toggleSubMenu=()=>
{
    setSubMenu(!subMenu)
}
   

   
    const toggleNow =() =>
    {
        setToggleMenu(!toggleMenu)
       
        
    }
    
    return(
            <>
            <div className="container">
                <div className="parent">
                         
                
                    <div className="child1">
                    <div className="logoDiv">
                        <a className="logo" href=""><Link to='/'> Accessible Tech Solutions</Link></a>
                    </div>
                    <div className="divButoaneNavigare">
                        <ul className="listaButoane">
                            <li>
                                <a href="acasa"><Link to="/">Acasă</Link></a>
                            </li>
                            <li>
                                <a href="Despre noi"><Link to="/DespreNoi">Despre noi</Link></a>
                            </li>
                            <li onMouseEnter={() => setSubMenu(true)} onMouseLeave={() => setSubMenu(false)}>
                                <a href="Servicii"><Link to='/Servicii'>Servicii<MdOutlineKeyboardArrowDown style={{height:'20px',width:'20px',fontSizeAdjust:'70px'}}/></Link></a>
                            </li>
                            {subMenu && 
                                <ul className="subMenu" onMouseEnter={() => setSubMenu(true)} onMouseLeave={() => setSubMenu(false)}>
                                    <li>
                                        <Link to='/SoftwareDevelopment'>Software Development</Link>
                                    </li>
                                    <li>
                                        <Link to="/NetworkingManagement">Networking</Link>
                                    </li>
                                    <li>
                                        <Link to="/Automatisations">Automatisations</Link>
                                    </li>
                                    <li>
                                        <Link to="/ManagementSolutions">Procese manageriale</Link>
                                    </li>

                                </ul>
                            }
                        </ul>
                    </div>
                    </div>
                    <div className="child2">
                        <a href="Contact"><NavLink to="/Contact">Contact</NavLink></a>
                    </div>
                    
                </div>
            </div>
            {/* <div id="brand" ><a href="/"> AtechSolutions</a> </div> */}
            <div className="mobileNavbar">
           
            <div id="hamburger-icon" onClick={toggleNow}> 
                <div className={`${deschis}bar1`}></div>
                <div className={`${deschis}bar2`}></div>
                <div className={`${deschis}bar3`}></div>
            </div>
            <div className="titleMobileNavbar">
                <span>Accessible Tech Solutions</span>
            </div>
                {toggleMenu &&
                (
                    <ul className="mobile-menu" onMouseLeave ={() => setSubMenu(false)}>
                    <li><Link to="/">Acasa</Link></li>
                    <li><Link to='/DespreNoi'>Despre noi</Link></li>
                    <li  onMouseEnter={() => setSubMenu(true)}   ><Link to='/Servicii'> Servicii</Link> <MdOutlineKeyboardArrowDown /></li>
                                {subMenu && 
                                <ul className="subMenu"  onMouseLeave ={() => setSubMenu(false)}>
                                   <Link to="/SoftwareDevelopment" >
                                    <li>
                                      Software Development
                                    </li>
                                    </Link>
                                    <Link to="/NetworkingManagement">
                                    <li>
                                        Networking
                                    </li>
                                    </Link>
                                    <Link to="/Automatisations">
                                    <li>
                                       Automatisations
                                    </li>
                                    </Link>
                                    <Link to="/ManagementSolutions">
                                    <li>
                                         Procese manageriale
                                    </li>
                                    </Link>
                                </ul>
                                    
                            
                                }
                    <li><Link to="/Contact">Contact</Link></li>
                    
                </ul>
                )}
               
            
            </div>
            </>
       
        );
}

export default Header;