import React from "react";
import axios from "axios";
import {useState,useEffect} from 'react-dom';



const url='https://pth.atechsolutions.ro/contact'


const nameRegex = /^[a-zA-Z]{2,30}( [a-zA-Z]{2,30})?$/;
const phoneRegex = /^[0-9\s\-()+]{10,20}$/;
const companyRegex = /^[a-zA-Z0-9\s\-]{2,50}$/;
const descriptionRegex = /^[a-zA-Z0-9\s\-\,\.\r\n]{10,500}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


export function ScrieInConsola()
{
    let firstName = document.getElementById("nume");
    let lastName =document.getElementById("prenume");
    let email=document.getElementById("email");
    let phone=document.getElementById("telefon");
    let company=document.getElementById("companie");
    let service=document.getElementById("serviciu");
    let description=document.getElementById("descriere");
    let alternativeService;
    service.value.trim();

    const input= document.getElementsByTagName("input")

    switch(service.value)
    {
      case 'Software Development':
        alternativeService=1;
        break;
      case 'Networking managemnt':
        alternativeService=2;
        break;
      case 'Automatisations':
        alternativeService=3;
        break;
      case 'Management solutions':
        alternativeService=4;
        break;
    }
    
 if (!nameRegex.test(firstName.value)) {
   firstName.style.borderColor="red";
   document.getElementById("labelNume").innerHTML='Format incorect';
   document.getElementById("labelNume").style.color="red";
   document.getElementById("labelNume").style.textAlign="left";
 }else{
  firstName.style.borderColor="rgba(43, 161, 96, 0.486)";
  
 }
 
 if (!nameRegex.test(lastName.value)) {
  lastName.style.borderColor="red";
 }else{
  lastName.style.borderColor="rgba(43, 161, 96, 0.486)";
 }
 
 if (!phoneRegex.test(phone.value)) {
  //phone.removeAttribute('style');
  //phone.classList.add("invalid");
  phone.style.borderColor="red";
 }else{
  phone.style.borderColor="rgba(43, 161, 96, 0.486)";
 }
 
 if (!companyRegex.test(company.value)) {
  company.style.borderColor="red";
 }else{
  company.style.borderColor="rgba(43, 161, 96, 0.486)";
 }
 
 
 if (!descriptionRegex.test(description.value)) {
    description.style.borderColor="red";
 }else{
  description.style.borderColor="rgba(43, 161, 96, 0.486)";
 }

 if(!emailRegex.test(email.value)) {
  email.style.borderColor="red";
 }else{
  email.style.borderColor="rgba(43, 161, 96, 0.486)";
 }
 
 
const dateJson={
"Serviciu": `${alternativeService}`,
"Prenume": `${lastName.value}`,
"Nume": `${firstName.value}`,
"Email": `${email.value}`,
"NrTelefon": `${phone.value}`,
"Companie": `${company.value}`,
"Mesaj": `${description.value}`
    }
    
  const sendPostRequest= async()=>
  {
    
      await axios.post(url,dateJson,{headers:{"Content-Type" : "application/json"}}).then(function(response)
        {
         // console.log(response);
        }).catch(function(error)
        {
          console.log(error.response.data);
        })
       
  }

  if(nameRegex.test(firstName.value) && nameRegex.test(lastName.value) && phoneRegex.test(phone.value) && companyRegex.test(company.value) && descriptionRegex.test(description.value) && emailRegex.test(email.value) )
  {
    sendPostRequest();
   
  }
  else
  {
    alert("Date introduse incorect")
  }


}
