import React from 'react'
import '../CSS/paginasd.css';

const PaginaSD=()=>
{
    return(
        <>
            <div className='continer-web-dev'>
                <div className='titleSD'>
                <span>
                    Software Development
                </span>
                </div>
                <div className='webDev'>
                    <span>
                        Web Development
                    </span>
                    <p>
    Crearea unui web site este cea mai buna modalitate  de a  prezenta o afacere, produse, de a vinde 
sau pentru a sustine o cauza, deoarece acesta poate fi accesat de pe orice dispozitiv non stop 
stisfăcand nevoile clienților oricând au nevoide de serviciile dumneavoastră. 
                    </p>
                    <p>
                    Nu doar că permite accesibilitate non stop dar reprezintă si primul contact dintre persoană și întreprindere,
prima impresie și  începutul drumului spre succes. 
                    </p>
                </div>
                    <div className='parinteEtape'>
                        <div className='copil-etape-1'>
                        <span>Conceperea  unui site consta in :</span>
                        <ol>
                          
                            <li>
                            -realizarea unui design pentru a  putea vedea o simulare a variantei finale a site-ului
                            </li>
                            <li>
                               - inceperea efectiva a transpunerii designului in cod prioritizand experienta utilizatorului </li>
                            <li>-alegerea unui domeniu care va reprezenta semnatura dumneavoastra in mediul online</li>
                            <li>-hostingul site-ului</li>
                        </ol>
                        </div>
                        <div className='copil-etape-2'>

                        </div>
                    </div>
                
            </div>
            <div className="container-baze-date">
            <p>Baze de date</p>
                <div className="parinte-baze-date">
               
                    <div>
                    </div>
                    <div>
                       <p>O bază de date este o colecție organizată de informații sau de date structurate, stocate electronic într-un computer. O bază de date este controlată, de regulă, de un sistem de management al bazelor de date.</p>
                       <p>Baza de date este este prima componenta de care trebuie sa se tina cont la nivelul unei companii pentru  a tine evidenta informatiilor intr-un mod organizat si pentru a le putea folosi in avantajul companiei.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaginaSD;